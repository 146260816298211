import AppSettings from "../config";

export const fetchReleaseNotes = (networkHeaders: any) => () => {
  return fetch(`${AppSettings.GOGOV_BASE_API_URL}/core/release-notes`, {
    method: "GET",
    headers: networkHeaders,
  }).then((res: any) => {
    if (res.status === 200) {
      return res.json();
    }
    if (res.error) throw new Error(res.error);
    throw new Error(`Failed to fetch release notes. Status Code ${res.status}`);
  });
};
