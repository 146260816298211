const config = {
  development: {
    DIRECT_UPLOAD_BUCKET: "867089416966-gg-web-stg-attachments",
    CONTENT_URL: "https://attachments-staging.govoutreach.com",
    ATTACHMENTS_URL: "https://attachments.govoutreach.com",
    GOOGLE_MAP_KEY: "",
    GOOGLE_GEOCODING_KEY: "AIzaSyDU1L0LwwZGDEOK7BfzwWiEwDN61pxOWxs",
    GOGOV_WEB_API_URL: "https://user.staging.govoutreach.com",
    GOGOV_WEB_URL: "https://user.staging.govoutreach.com",
    GOGOV_MOBILE_URL: "https://m.staging.govoutreach.com",
    GOGOV_BASE_API_URL: "https://api.staging.govoutreach.com",
    CITIZEN_API_URL: "https://api.staging.govoutreach.com/citizen",
    CITIZENS_API_URL: "https://api.staging.govoutreach.com/citizens",
    IDENTITY_API_URL: "https://api.staging.govoutreach.com/users",
    PRODUCTS_API_URL: "https://api.staging.govoutreach.com/products",
    ADMIN_API_URL: "https://api.staging.govoutreach.com/admin",
    INTERNAL_ADMIN_API_URL: "https://internal-admin.staging.govoutreach.com/api",
    CALENDARS_API_URL: "https://api.staging.govoutreach.com/calendars",
    CLIENTS_API_URL: "https://api.staging.govoutreach.com/clients",
    DIRECT_UPLOAD_API_URL: "https://api.staging.govoutreach.com/uploads",
    FILE_UPLOAD_API_URL: "https://api.staging.govoutreach.com/core/file",
    MOBILE_BRANDING_API_URL: "https://api.staging.govoutreach.com/core/mobile/branding",
    AGENCY_SEAL_API_URL: "https://api.staging.govoutreach.com/core/agency/seal",
    EMPLOYEES_API_URL: "https://api.staging.govoutreach.com/employees",
    DEPARTMENTS_API_URL: "https://api.staging.govoutreach.com/departments",
    SEARCH_API_URL: "https://api.staging.govoutreach.com/search",
    CRM_CUSTOM_FIELDS_API_URL: "https://api.staging.govoutreach.com/crm/custom-fields",
    CRM_REQUESTS_API_URL: "https://api.staging.govoutreach.com/crm/requests",
    NOTIFICATIONS_API_URL: "https://api.staging.govoutreach.com/notifications",
    CE_SETTINGS_API_URL: "https://api.staging.govoutreach.com/admin/code",
    CE_CUSTOM_FIELDS_API_URL: "https://api.staging.govoutreach.com/ce/custom-fields",
    PERMITS_FORMS_API_URL: "https://api.staging.govoutreach.com/permits/forms",
    PERMITS_INSPECTION_TYPES_API_URL: "https://api.staging.govoutreach.com/inspections/types",
    PERMITS_INSPECTION_API_URL: "https://api.staging.govoutreach.com/inspections",
    PERMITS_TYPES_API_URL: "https://api.staging.govoutreach.com/permits/types",
    PERMITS_VIEWS_API_URL: "https://api.staging.govoutreach.com/permits/views",
    PERMITS_API_URL: "https://api.staging.govoutreach.com/permits",
    PERMITS_STATUSES_API_URL: "https://api.staging.govoutreach.com/permits/statuses",
    COMMENTS_API_URL: "https://api.staging.govoutreach.com/comments",
    PAYMENTS_API_URL: "https://api.staging.govoutreach.com/payments",
    DOCUMENT: "https://api.staging.govoutreach.com/documents",
    DOCUMENT_TYPES: "https://api.staging.govoutreach.com/documents/types",
    DOCUMENT_SETTINGS: "https://api.staging.govoutreach.com/admin/documents",
    FEATURE_FLAGS_API_URL: "https://api.staging.govoutreach.com/core/feature-flags",
    ASSETS_URL: "https://assets-staging.govoutreach.com",
    SENTRY_DSN: "https://143adbbe09b44249bb1c406a01b0fdac@o396450.ingest.sentry.io/5252806",
    LOGROCKET_SLUG: "s5ctc4/web-app-staging-cxc5n",
    STRIPE_TEST_PUBLIC_KEY:
      "pk_test_51Ij9KVDsqRmHarDvH3TkwaDO8qWMYQX4HHvOaYcwHw778vkslbRnsFMRzv5YOCVhl8AwZBMZrCfLv94Cq3nyB5te00pa1TI5fg",
    STRIPE_LIVE_PUBLIC_KEY:
      "pk_live_51Ij9KVDsqRmHarDvZHiL7Hkl1JBGJa92KJbXgPV6eWLzDvOHXuMtKaa5UsAeHGFna52U700G41VO9BYpPctmtMUP000YS7M36w",
    FORCE_NEW_LOOK_LISTS: true,
    MIXPANEL_TOKEN: "f11dfba8d1143733d77c4856ba2eed62",
    DISABLE_LOCATIONS: false,
    DISABLE_IMPORTS: false,
    DISABLE_EVENT_LOG: false,
    DISABLE_UPLOAD_TOOL: false,
    LOCAL_DEV_ENV: true,
    CONTACTS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/ContactsStandardImportTemplate.csv",
    PERMITS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/PermitsStandardImportTemplate.csv",
    PERMIT_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/PermitAttachmentsStandardImportTemplate.csv",
    CRM_REQUESTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CRMRequestsStandardImportTemplate.csv",
    CRM_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CRMAttachmentsStandardImportTemplate.csv",
    CRM_LOGS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/CRMLogsStandardImportTemplate.csv",
    CODE_CASES_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/CodeCasesStandardImportTemplate.csv",
    CODE_ACTIONS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeActionsStandardImportTemplate.csv",
    CODE_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeAttachmentsStandardImportTemplate.csv",
    CODE_VIOLATIONS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeViolationsStandardImportTemplate.csv",
    CODE_ADDRESSES_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeAddressesStandardImportTemplate.csv",
    RELEASE_NOTES_URL: "https://gogov.releasenotes.io/",
    EMAIL_SUPPRESSION_LIST_API_URL: "https://api.staging.govoutreach.com/core/mail/suppression-list",
  },
  staging: {
    DIRECT_UPLOAD_BUCKET: "867089416966-gg-web-stg-attachments",
    CONTENT_URL: "https://attachments-staging.govoutreach.com",
    ATTACHMENTS_URL: "https://attachments.govoutreach.com",
    GOOGLE_GEOCODING_KEY: "AIzaSyDU1L0LwwZGDEOK7BfzwWiEwDN61pxOWxs",
    GOOGLE_MAP_KEY: "AIzaSyCIVurGluwQdJ8rvPltx8oRIzc76D3oAQk",
    GOGOV_WEB_API_URL: "https://user.staging.govoutreach.com",
    GOGOV_WEB_URL: "https://user.staging.govoutreach.com",
    GOGOV_MOBILE_URL: "https://m.staging.govoutreach.com",
    GOGOV_BASE_API_URL: "https://api.staging.govoutreach.com",
    CITIZEN_API_URL: "https://api.staging.govoutreach.com/citizen",
    CITIZENS_API_URL: "https://api.staging.govoutreach.com/citizens",
    IDENTITY_API_URL: "https://api.staging.govoutreach.com/users",
    PRODUCTS_API_URL: "https://api.staging.govoutreach.com/products",
    ADMIN_API_URL: "https://api.staging.govoutreach.com/admin",
    INTERNAL_ADMIN_API_URL: "https://internal-admin.staging.govoutreach.com/api",
    CALENDARS_API_URL: "https://api.staging.govoutreach.com/calendars",
    CLIENTS_API_URL: "https://api.staging.govoutreach.com/clients",
    DIRECT_UPLOAD_API_URL: "https://api.staging.govoutreach.com/uploads",
    FILE_UPLOAD_API_URL: "https://api.staging.govoutreach.com/core/file",
    MOBILE_BRANDING_API_URL: "https://api.staging.govoutreach.com/core/mobile/branding",
    AGENCY_SEAL_API_URL: "https://api.staging.govoutreach.com/core/agency/seal",
    EMPLOYEES_API_URL: "https://api.staging.govoutreach.com/employees",
    DEPARTMENTS_API_URL: "https://api.staging.govoutreach.com/departments",
    SEARCH_API_URL: "https://api.staging.govoutreach.com/search",
    CRM_REQUESTS_API_URL: "https://api.staging.govoutreach.com/crm/requests",
    CRM_CUSTOM_FIELDS_API_URL: "https://api.staging.govoutreach.com/crm/custom-fields",
    CE_SETTINGS_API_URL: "https://api.staging.govoutreach.com/admin/code",
    CE_CUSTOM_FIELDS_API_URL: "https://api.staging.govoutreach.com/ce/custom-fields",
    PERMITS_FORMS_API_URL: "https://api.staging.govoutreach.com/permits/forms",
    PERMITS_INSPECTION_TYPES_API_URL: "https://api.staging.govoutreach.com/inspections/types",
    PERMITS_INSPECTION_API_URL: "https://api.staging.govoutreach.com/inspections",
    PERMITS_TYPES_API_URL: "https://api.staging.govoutreach.com/permits/types",
    PERMITS_VIEWS_API_URL: "https://api.staging.govoutreach.com/permits/views",
    PERMITS_API_URL: "https://api.staging.govoutreach.com/permits",
    PERMITS_STATUSES_API_URL: "https://api.staging.govoutreach.com/permits/statuses",
    COMMENTS_API_URL: "https://api.staging.govoutreach.com/comments",
    PAYMENTS_API_URL: "https://api.staging.govoutreach.com/payments",
    DOCUMENT: "https://api.staging.govoutreach.com/documents",
    DOCUMENT_TYPES: "https://api.staging.govoutreach.com/documents/types",
    DOCUMENT_SETTINGS: "https://api.staging.govoutreach.com/admin/documents",
    NOTIFICATIONS_API_URL: "https://api.staging.govoutreach.com/notifications",
    FEATURE_FLAGS_API_URL: "https://api.staging.govoutreach.com/core/feature-flags",
    ASSETS_URL: "https://assets-staging.govoutreach.com",
    SYSTEM_STATUS_URL: "https://status.gogovapps.com",
    SENTRY_DSN: "https://143adbbe09b44249bb1c406a01b0fdac@o396450.ingest.sentry.io/5252806",
    LOGROCKET_SLUG: "s5ctc4/web-app-staging-cxc5n",
    STRIPE_TEST_PUBLIC_KEY:
      "pk_test_51Ij9KVDsqRmHarDvH3TkwaDO8qWMYQX4HHvOaYcwHw778vkslbRnsFMRzv5YOCVhl8AwZBMZrCfLv94Cq3nyB5te00pa1TI5fg",
    STRIPE_LIVE_PUBLIC_KEY:
      "pk_live_51Ij9KVDsqRmHarDvZHiL7Hkl1JBGJa92KJbXgPV6eWLzDvOHXuMtKaa5UsAeHGFna52U700G41VO9BYpPctmtMUP000YS7M36w",
    FORCE_NEW_LOOK_LISTS: false,
    MIXPANEL_TOKEN: "f11dfba8d1143733d77c4856ba2eed62",
    DATADOG_RUM_TOKEN: "pub6fca90cc7978f92d2bd1f73bdd90d702",
    DATADOG_RUM_APP_ID: "d0e40921-5139-4859-979f-fc3be06c5e2b",
    DISABLE_LOCATIONS: false,
    DISABLE_IMPORTS: false,
    DISABLE_EVENT_LOG: false,
    DISABLE_UPLOAD_TOOL: false,
    LOCAL_DEV_ENV: false,
    CONTACTS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/ContactsStandardImportTemplate.csv",
    PERMITS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/PermitsStandardImportTemplate.csv",
    PERMIT_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/PermitAttachmentsStandardImportTemplate.csv",
    CRM_REQUESTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CRMRequestsStandardImportTemplate.csv",
    CRM_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CRMAttachmentsStandardImportTemplate.csv",
    CRM_LOGS_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/CRMLogsStandardImportTemplate.csv",
    CODE_CASES_IMPORT_TEMPLATE: "https://assets-staging.govoutreach.com/templates/CodeCasesStandardImportTemplate.csv",
    CODE_ACTIONS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeActionsStandardImportTemplate.csv",
    CODE_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeAttachmentsStandardImportTemplate.csv",
    CODE_VIOLATIONS_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeViolationsStandardImportTemplate.csv",
    CODE_ADDRESSES_IMPORT_TEMPLATE:
      "https://assets-staging.govoutreach.com/templates/CodeAddressesStandardImportTemplate.csv",
    RELEASE_NOTES_URL: "https://gogov.releasenotes.io/",
    EMAIL_SUPPRESSION_LIST_API_URL: "https://api.staging.govoutreach.com/core/mail/suppression-list",
  },
  preprod: {
    DIRECT_UPLOAD_BUCKET: "867089416966-preprod-gg-web-stg-attachments",
    CONTENT_URL: "https://attachments-preprod.govoutreach.com",
    ATTACHMENTS_URL: "https://attachments.govoutreach.com",
    GOOGLE_GEOCODING_KEY: "AIzaSyDU1L0LwwZGDEOK7BfzwWiEwDN61pxOWxs",
    GOOGLE_MAP_KEY: "AIzaSyCIVurGluwQdJ8rvPltx8oRIzc76D3oAQk",
    GOGOV_WEB_API_URL: "https://user.preprod.govoutreach.com",
    GOGOV_WEB_URL: "https://user.preprod.govoutreach.com",
    GOGOV_MOBILE_URL: "https://m.preprod.govoutreach.com",
    GOGOV_BASE_API_URL: "https://api.preprod.govoutreach.com",
    CITIZEN_API_URL: "https://api.preprod.govoutreach.com/citizen",
    CITIZENS_API_URL: "https://api.preprod.govoutreach.com/citizens",
    IDENTITY_API_URL: "https://api.preprod.govoutreach.com/users",
    PRODUCTS_API_URL: "https://api.preprod.govoutreach.com/products",
    ADMIN_API_URL: "https://api.preprod.govoutreach.com/admin",
    INTERNAL_ADMIN_API_URL: "https://internal-admin.staging.govoutreach.com/api",
    CALENDARS_API_URL: "https://api.preprod.govoutreach.com/calendars",
    CLIENTS_API_URL: "https://api.preprod.govoutreach.com/clients",
    DIRECT_UPLOAD_API_URL: "https://api.preprod.govoutreach.com/uploads",
    FILE_UPLOAD_API_URL: "https://api.preprod.govoutreach.com/core/file",
    MOBILE_BRANDING_API_URL: "https://api.preprod.govoutreach.com/core/mobile/branding",
    AGENCY_SEAL_API_URL: "https://api.preprod.govoutreach.com/core/agency/seal",
    EMPLOYEES_API_URL: "https://api.preprod.govoutreach.com/employees",
    DEPARTMENTS_API_URL: "https://api.preprod.govoutreach.com/departments",
    SEARCH_API_URL: "https://api.preprod.govoutreach.com/search",
    CRM_REQUESTS_API_URL: "https://api.preprod.govoutreach.com/crm/requests",
    CRM_CUSTOM_FIELDS_API_URL: "https://api.preprod.govoutreach.com/crm/custom-fields",
    CE_SETTINGS_API_URL: "https://api.preprod.govoutreach.com/admin/code",
    CE_CUSTOM_FIELDS_API_URL: "https://api.preprod.govoutreach.com/ce/custom-fields",
    CE_LETTERS_API_URL: "https://api.preprod.govoutreach.com/ce/letters",
    PERMITS_FORMS_API_URL: "https://api.preprod.govoutreach.com/permits/forms",
    PERMITS_INSPECTION_TYPES_API_URL: "https://api.preprod.govoutreach.com/inspections/types",
    PERMITS_INSPECTION_API_URL: "https://api.preprod.govoutreach.com/inspections",
    PERMITS_TYPES_API_URL: "https://api.preprod.govoutreach.com/permits/types",
    PERMITS_VIEWS_API_URL: "https://api.preprod.govoutreach.com/permits/views",
    PERMITS_API_URL: "https://api.preprod.govoutreach.com/permits",
    PERMITS_STATUSES_API_URL: "https://api.preprod.govoutreach.com/permits/statuses",
    COMMENTS_API_URL: "https://api.preprod.govoutreach.com/comments",
    PAYMENTS_API_URL: "https://api.preprod.govoutreach.com/payments",
    DOCUMENT: "https://api.preprod.govoutreach.com/documents",
    DOCUMENT_TYPES: "https://api.preprod.govoutreach.com/documents/types",
    DOCUMENT_SETTINGS: "https://api.preprod.govoutreach.com/admin/documents",
    NOTIFICATIONS_API_URL: "https://api.preprod.govoutreach.com/notifications",
    FEATURE_FLAGS_API_URL: "https://api.preprod.govoutreach.com/core/feature-flags",
    ASSETS_URL: "https://assets-preprod.govoutreach.com",
    SYSTEM_STATUS_URL: "https://status.gogovapps.com",
    SENTRY_DSN: "https://143adbbe09b44249bb1c406a01b0fdac@o396450.ingest.sentry.io/5252806",
    LOGROCKET_SLUG: "s5ctc4/web-app-preprod-cxc5n",
    STRIPE_TEST_PUBLIC_KEY:
      "pk_test_51Ij9KVDsqRmHarDvH3TkwaDO8qWMYQX4HHvOaYcwHw778vkslbRnsFMRzv5YOCVhl8AwZBMZrCfLv94Cq3nyB5te00pa1TI5fg",
    STRIPE_LIVE_PUBLIC_KEY:
      "pk_live_51Ij9KVDsqRmHarDvZHiL7Hkl1JBGJa92KJbXgPV6eWLzDvOHXuMtKaa5UsAeHGFna52U700G41VO9BYpPctmtMUP000YS7M36w",
    FORCE_NEW_LOOK_LISTS: false,
    MIXPANEL_TOKEN: "f11dfba8d1143733d77c4856ba2eed62",
    DATADOG_RUM_TOKEN: "pub4a15b46b304feaeeebe81f49a1d8e05d",
    DATADOG_RUM_APP_ID: "be8d0dfc-dfb0-41f0-9f0d-b38c1b8c73f3",
    DISABLE_LOCATIONS: false,
    DISABLE_IMPORTS: false,
    DISABLE_EVENT_LOG: false,
    DISABLE_UPLOAD_TOOL: false,
    LOCAL_DEV_ENV: false,
    CONTACTS_IMPORT_TEMPLATE: "https://assets-preprod.govoutreach.com/templates/ContactsStandardImportTemplate.csv",
    PERMITS_IMPORT_TEMPLATE: "https://assets-preprod.govoutreach.com/templates/PermitsStandardImportTemplate.csv",
    PERMIT_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/PermitAttachmentsStandardImportTemplate.csv",
    CRM_REQUESTS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CRMRequestsStandardImportTemplate.csv",
    CRM_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CRMAttachmentsStandardImportTemplate.csv",
    CRM_LOGS_IMPORT_TEMPLATE: "https://assets-preprod.govoutreach.com/templates/CRMLogsStandardImportTemplate.csv",
    CODE_CASES_IMPORT_TEMPLATE: "https://assets-preprod.govoutreach.com/templates/CodeCasesStandardImportTemplate.csv",
    CODE_ACTIONS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CodeActionsStandardImportTemplate.csv",
    CODE_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CodeAttachmentsStandardImportTemplate.csv",
    CODE_VIOLATIONS_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CodeViolationsStandardImportTemplate.csv",
    CODE_ADDRESSES_IMPORT_TEMPLATE:
      "https://assets-preprod.govoutreach.com/templates/CodeAddressesStandardImportTemplate.csv",
    RELEASE_NOTES_URL: "https://gogov.releasenotes.io/",
  },
  production: {
    DIRECT_UPLOAD_BUCKET: "331447492604-gg-web-prod-attachments",
    CONTENT_URL: "https://content.govoutreach.com",
    ATTACHMENTS_URL: "https://attachments.govoutreach.com",
    GOOGLE_GEOCODING_KEY: "AIzaSyC48hA-wwW2JTOFU5arMte7UJyLgd_RqAY",
    GOOGLE_MAP_KEY: "AIzaSyCIVurGluwQdJ8rvPltx8oRIzc76D3oAQk",
    GOGOV_WEB_API_URL: "https://user.govoutreach.com",
    GOGOV_WEB_URL: "https://user.govoutreach.com",
    GOGOV_MOBILE_URL: "https://m.govoutreach.com",
    GOGOV_BASE_API_URL: "https://api.govoutreach.com",
    CITIZEN_API_URL: "https://api.govoutreach.com/citizen",
    CITIZENS_API_URL: "https://api.govoutreach.com/citizens",
    PRODUCTS_API_URL: "https://api.govoutreach.com/products",
    IDENTITY_API_URL: "https://api.govoutreach.com/users",
    ADMIN_API_URL: "https://api.govoutreach.com/admin",
    INTERNAL_ADMIN_API_URL: "https://internal-admin.govoutreach.com/api",
    CALENDARS_API_URL: "https://api.govoutreach.com/calendars",
    CLIENTS_API_URL: "https://api.govoutreach.com/clients",
    DIRECT_UPLOAD_API_URL: "https://api.govoutreach.com/uploads",
    FILE_UPLOAD_API_URL: "https://api.govoutreach.com/core/file",
    MOBILE_BRANDING_API_URL: "https://api.govoutreach.com/core/mobile/branding",
    AGENCY_SEAL_API_URL: "https://api.govoutreach.com/core/agency/seal",
    EMPLOYEES_API_URL: "https://api.govoutreach.com/employees",
    DEPARTMENTS_API_URL: "https://api.govoutreach.com/departments",
    SEARCH_API_URL: "https://api.govoutreach.com/search",
    CRM_CUSTOM_FIELDS_API_URL: "https://api.govoutreach.com/crm/custom-fields",
    CRM_REQUESTS_API_URL: "https://api.govoutreach.com/crm/requests",
    CE_SETTINGS_API_URL: "https://api.govoutreach.com/admin/code",
    CE_CUSTOM_FIELDS_API_URL: "https://api.govoutreach.com/ce/custom-fields",
    PERMITS_FORMS_API_URL: "https://api.govoutreach.com/permits/forms",
    PERMITS_INSPECTION_TYPES_API_URL: "https://api.govoutreach.com/inspections/types",
    PERMITS_INSPECTION_API_URL: "https://api.govoutreach.com/inspections",
    PERMITS_TYPES_API_URL: "https://api.govoutreach.com/permits/types",
    PERMITS_VIEWS_API_URL: "https://api.govoutreach.com/permits/views",
    PERMITS_API_URL: "https://api.govoutreach.com/permits",
    COMMENTS_API_URL: "https://api.govoutreach.com/comments",
    PAYMENTS_API_URL: "https://api.govoutreach.com/payments",
    DOCUMENT: "https://api.govoutreach.com/documents",
    DOCUMENT_TYPES: "https://api.govoutreach.com/documents/types",
    DOCUMENT_SETTINGS: "https://api.govoutreach.com/admin/documents",
    NOTIFICATIONS_API_URL: "https://api.govoutreach.com/notifications",
    FEATURE_FLAGS_API_URL: "https://api.govoutreach.com/core/feature-flags",
    ASSETS_URL: "https://assets.govoutreach.com",
    SYSTEM_STATUS_URL: "https://status.gogovapps.com",
    SENTRY_DSN: "https://ac20efc379254bd58c674f2f25978496@o396450.ingest.sentry.io/5249806",
    LOGROCKET_SLUG: "s5ctc4/web-app-production",
    STRIPE_LIVE_PUBLIC_KEY:
      "pk_live_51IkZA1Fe4VpqOTed7ixW3CZZeBiMtpv0N4YIDbw4r4LPFIqF3j6cBSZ8wnGYDCcFDyhU1bc5gGeYqjvuf3WBjXcO00q0r8oyCH",
    STRIPE_TEST_PUBLIC_KEY:
      "pk_test_51IkZA1Fe4VpqOTedTRaWW2h2DvL1PT9tLrG93W4wOpUog6PbM06BDjBXjsfue20YhlroQ16AFQhPR0ilBHrhKAAp00RHHIQYpK",
    FORCE_NEW_LOOK_LISTS: false,
    MIXPANEL_TOKEN: "d8f92e0ba42c678798a222435e26ed8f",
    DATADOG_RUM_TOKEN: "pub784caac90909316b1342981d49a91355",
    DATADOG_RUM_APP_ID: "516f01dc-a24c-4f3b-a694-d5698c3933fa",
    DISABLE_LOCATIONS: false,
    DISABLE_IMPORTS: true,
    DISABLE_UPLOAD_TOOL: false,
    DISABLE_EVENT_LOG: false,
    LOCAL_DEV_ENV: false,
    CONTACTS_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/ContactsStandardImportTemplate.csv",
    PERMITS_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/PermitsStandardImportTemplate.csv",
    PERMIT_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets.govoutreach.com/templates/PermitAttachmentsStandardImportTemplate.csv",
    CRM_REQUESTS_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/CRMRequestsStandardImportTemplate.csv",
    CRM_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets.govoutreach.com/templates/CRMAttachmentsStandardImportTemplate.csv",
    CRM_LOGS_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/CRMLogsStandardImportTemplate.csv",
    CODE_CASES_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/CodeCasesStandardImportTemplate.csv",
    CODE_ACTIONS_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/CodeActionsStandardImportTemplate.csv",
    CODE_ATTACHMENTS_IMPORT_TEMPLATE:
      "https://assets.govoutreach.com/templates/CodeAttachmentsStandardImportTemplate.csv",
    CODE_VIOLATIONS_IMPORT_TEMPLATE:
      "https://assets.govoutreach.com/templates/CodeViolationsStandardImportTemplate.csv",
    CODE_ADDRESSES_IMPORT_TEMPLATE: "https://assets.govoutreach.com/templates/CodeAddressesStandardImportTemplate.csv",
    RELEASE_NOTES_URL: "https://gogov.releasenotes.io/",
    EMAIL_SUPPRESSION_LIST_API_URL: "https://api.govoutreach.com/core/mail/suppression-list",
  },
};

export default {
  ...config[import.meta.env.VITE_REACT_APP_STAGE || "development"],
};
