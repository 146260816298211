import React, { useMemo, useState } from "react";
import { useMedia } from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Link, useParams, useHistory } from "react-router-dom";
import { Popup, Button, Dropdown, Icon, Menu, Image, Divider, Label, Header } from "semantic-ui-react";
import SideNav, { Nav, NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import styled from "styled-components";
import { usePersistedState } from "../hooks/use-persisted-state";
import { useFeatureFlag } from "../hooks/use-feature-flag";
import { fetchGetProducts, hasProduct } from "../queries/products";
import permissionsSelectors from "../selectors/permissions";
import Products from "../constants/products";
import AppSettings from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCertificate, faGear, faMapLocationDot, faUsers } from "@fortawesome/pro-regular-svg-icons";
import "./EmployeeWrapper.scss";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import config from "../config";
import { ReleaseNotesContextProvider, useReleaseNotes } from "../hooks/useReleaseNotes";
import usePermitsApplicationListUrl from "../hooks/usePermitApplicationListUrl";
import identitySelectors from "../selectors/identity";
import { ContactAvatar } from "../features/permits/components/ContactAvatar";

const NavWidth = 180;
const NavWidthCollapsed = 60;
const NavHeader = styled.div<{ expanded: boolean }>`
  img {
    margin: 15px;
    margin-bottom: 16px;
    max-height: 40px;
  }
`;
const CollapseButton = styled(Button)<{ visible: boolean }>`
  background-color: white !important;
  box-shadow:
    rgb(9 30 66 / 8%) 0px 0px 0px 1px,
    rgb(9 30 66 / 8%) 0px 2px 4px 1px !important;
  position: absolute;
  top: ${(props: any) => (props.visible ? 36 : -40)}px;
  transition: left 0.2s linear !important;
  &.NavWidth {
    left: ${NavWidth - 15}px;
  }
  &.NavWidthCollapsed {
    left: ${NavWidthCollapsed - 15}px;
  }
`;

const StyledSideNav = styled(SideNav)`
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #323a52;
  min-width: ${(props: any) => (props.expanded ? NavWidth : NavWidthCollapsed)}px !important;
  z-index: 1000 !important;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;
const StyledNav = styled(Nav)`
  background-color: #323a52;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;

  && > [class*="sidenav-navitem--"] {
    > [class*="navitem--"] {
      [class*="navicon--"] {
        &,
        > * {
          color: white;
          opacity: 1;
        }

        i {
          opacity: 1;
        }

        [class*="navchip--"] {
          width: 5px;
          height: 100%;
          position: absolute;
          left: 0;
        }
      }
      [class*="navtext--"] {
        &,
        > * {
          color: white;
          font-size: 1em;
        }
      }
    }
  }
  && > [class*="sidenav-navitem--"]:hover {
    > [class*="navitem--"] {
      background-color: rgba(1, 1, 1, 0.1);

      i {
        opacity: 1;
      }
    }
  }
`;
StyledNav.defaultProps = Nav.defaultProps;
const StyledBottomNav = styled(StyledNav)`
  margin: '0px',
  marginTop: 'auto'
`;
const SquaredMenu = styled(Menu)`
  border-radius: 0 !important;
`;
const Main = styled.main<{ expanded: boolean }>`
  position: relative;
  overflow-y: auto;
  padding: 0 0;
  height: 100vh;
`;
const MobileMenuItem = ({ item }: any) => {
  if (item.options) {
    return (
      <>
        {item.options.map((subitem: any) => (
          <Menu.Item
            key={subitem.text}
            as={subitem.href !== undefined ? "a" : Link}
            href={subitem.href}
            to={subitem.to}
          >
            <Icon name={subitem.icon} />
            {subitem.text}
            {subitem.badge}
          </Menu.Item>
        ))}
      </>
    );
  }

  return (
    <Menu.Item as={item.href !== undefined ? "a" : Link} href={item.href} to={item.to}>
      {item.iconSource === "semantic" && <Icon name={item.icon} />}
      {item.iconSource === "font awesome" && item.icon}
      {item.text}
      {item.badge}
    </Menu.Item>
  );
};

const DesktopMenuItem = ({ item, expanded, firstName, lastName, email }: any) => {
  const history = useHistory();
  const selected = item?.isSelected && item.isSelected();
  const releaseNotes = useReleaseNotes();
  const onClick = (item: any) => () => {
    if (item.to) {
      history.push(item.to);
    } else if (item.href && item.tab == "new") {
      window.open(item.href);
    } else if (item.href) {
      window.location.href = item.href;
    }
  };
  const isBottomAccountPopup = item.text == "Account";

  if (item.options) {
    return (
      <Popup
        key={item.text}
        hoverable
        position='right center'
        flowing
        className={isBottomAccountPopup ? "employee-wrapper-account-popup" : "employee-wrapper-admin-popup"}
        trigger={
          // Render avatar for the account menu item
          isBottomAccountPopup ? (
            <div className='tw-ml-[2px] tw-mt-2 tw-flex tw-cursor-pointer tw-items-center tw-pl-4'>
              <ContactAvatar firstName={firstName} lastName={lastName} size='32px' />
              {expanded && (
                <span
                  className='ellipses-overflow tw-ml-3 tw-whitespace-nowrap tw-text-white tw-opacity-[.95]'
                  style={{ maxWidth: 110 }}
                >
                  {`${firstName} ${lastName}`}
                </span>
              )}
            </div>
          ) : (
            <NavItem>
              <NavIcon>
                <div style={{ position: "relative" }}>
                  {item.iconSource == "semantic" && <Icon name={item.icon} size='large' />}
                  {item.iconSource == "font awesome" && item.icon}
                  {item.badge}
                </div>
              </NavIcon>
              <NavText>{item.text}</NavText>
              {item.isAdmin && releaseNotes.unseenCount > 0 && (
                <Label style={{ top: expanded ? "26%" : "0%" }} size='mini' className='release-notes-badge-global-nav'>
                  {releaseNotes.unseenCount}
                </Label>
              )}
            </NavItem>
          )
        }
      >
        <Menu secondary vertical fluid={isBottomAccountPopup}>
          {isBottomAccountPopup && (
            <>
              <div className='tw-flex tw-p-2'>
                <ContactAvatar firstName={firstName} lastName={lastName} size='40px' />
                <div className='tw-ml-4 tw-mt-1 tw-text-left'>
                  <Header as='h5' className='tw-mb-1'>{`${firstName} ${lastName}`}</Header>
                  <Header.Subheader className='tw-text-sm'>{email}</Header.Subheader>
                </div>
              </div>
              <Divider />
            </>
          )}
          {/* If the subitem has it's "show" field blank (undefined) I'm assuming we want it to be shown. Otherwise check for truthy-ness */}
          {item.options
            .filter((subitem: any) => subitem.show || subitem.show === undefined)
            .map((subitem: any) => (
              <Menu.Item
                key={subitem.text}
                as={subitem.href !== undefined ? "a" : Link}
                href={subitem.href}
                to={subitem.to}
                onClick={() => {
                  if (subitem.isReleaseNotes) {
                    releaseNotes.open();
                    releaseNotes.clearBadge();
                  }
                }}
              >
                {subitem.icon && (
                  <Icon.Group style={{ width: 48 }}>
                    <Icon name={subitem.icon} color={subitem.iconColor} />
                  </Icon.Group>
                )}
                {subitem.isReleaseNotes && releaseNotes.unseenCount > 0 && (
                  <Label size='small' className='release-notes-badge'>
                    {releaseNotes.unseenCount}
                  </Label>
                )}
                {subitem.text}
              </Menu.Item>
            ))}
        </Menu>
      </Popup>
    );
  }

  return (
    <Popup
      inverted
      disabled={expanded}
      content={item.text}
      position='right center'
      trigger={
        <NavItem style={{ backgroundColor: selected ? "rgba(255,255,255,.3)" : "transparent" }} onClick={onClick(item)}>
          <NavIcon>
            <div style={{ position: "relative" }}>
              {item.iconSource == "semantic" && <Icon name={item.icon} size='large' />}
              {item.iconSource == "font awesome" && item.icon}
              {item.badge}
            </div>
          </NavIcon>
          <NavText>{item.text}</NavText>
        </NavItem>
      }
    />
  );
};

const MenuItems = ({ mobile, expanded }: { mobile?: boolean; expanded?: boolean }) => {
  const params = useParams<{ site: string }>();
  const dispatch = useDispatch();
  const productsQuery = useQuery(["products", params.site], fetchGetProducts(dispatch));
  const hasCrm = hasProduct(productsQuery, Products.CRM);
  const hasCe = hasProduct(productsQuery, Products.CE);
  const hasCommunications = hasProduct(productsQuery, Products.COMMUNICATIONS);
  const hasPermits = hasProduct(productsQuery, Products.PERMITS);

  const permitApplicationListUrl = usePermitsApplicationListUrl();
  const hasUrl = permitApplicationListUrl.urlExists();
  const searchQueryString = hasUrl ? `?${permitApplicationListUrl.getSearch()}` : "";

  const featureCode = useFeatureFlag("ce.new_look_list");
  const linkToCodePHP = !AppSettings.FORCE_NEW_LOOK_LISTS && !featureCode.isEnabled;
  // const featureCrm = useFeatureFlag('crm.new_look_list');
  const canManageCommunications = useSelector(permissionsSelectors.can("manage_communications"));

  // For CRM and CE, we don't have explicit "permissions" for who can do what, like we do for permits and communications. For
  // now, I'm just using the roles to check for permissions in the UI.
  const canReviewCrm =
    useSelector(identitySelectors.isCrmAdmin) ||
    useSelector(identitySelectors.isDepartmentAdmin) ||
    useSelector(identitySelectors.isCrmUser);
  const canReviewCe = useSelector(identitySelectors.isCeAdmin) || useSelector(identitySelectors.isCeUser);

  const canReviewPermits = useSelector(permissionsSelectors.can("review_permits"));
  const firstName = useSelector(identitySelectors.getUserFirstName);
  const lastName = useSelector(identitySelectors.getUserLastName);
  const username = useSelector(identitySelectors.getUserUsername);
  const email = useSelector(identitySelectors.getUserEmail);

  const structure = {
    top: [
      {
        show: hasCrm && canReviewCrm,
        icon: "id card outline",
        iconSource: "semantic",
        text: "Requests",
        to: AppSettings.FORCE_NEW_LOOK_LISTS ? `/${params.site}/crm` : undefined,
        href: !AppSettings.FORCE_NEW_LOOK_LISTS
          ? `${AppSettings.GOGOV_WEB_URL}/${params.site}/internal.php`
          : undefined,
        isSelected: () => window.location.pathname.includes("/crm"),
      },
      {
        show: hasCe && canReviewCe,
        icon: "briefcase",
        iconSource: "semantic",
        text: "Code Cases",
        to: !linkToCodePHP ? `/${params.site}/code` : undefined,
        href: linkToCodePHP ? `${AppSettings.GOGOV_WEB_URL}/${params.site}/internal.php` : undefined,
        isSelected: () => window.location.pathname.includes("/code"),
      },
      {
        show: hasCommunications && canManageCommunications,
        icon: "bullhorn",
        iconSource: "semantic",
        text: "Notify",
        to: `/${params.site}/communications`,
        isSelected: () => window.location.pathname.includes("/communications"),
      },
      {
        show: hasPermits && canReviewPermits,
        icon: mobile ? (
          <FontAwesomeIcon icon={faFileCertificate} size='1x' inverse className='tw-mr-8 tw-opacity-90' />
        ) : (
          <FontAwesomeIcon icon={faFileCertificate} size='xl' inverse />
        ),
        iconSource: "font awesome",
        text: "Permits",
        to: `/${params.site}/permits/applications/${searchQueryString}`,
        isSelected: () => window.location.pathname.includes("/permits"),
      },
    ],
    middle: [
      {
        show: true,
        icon: mobile ? (
          <FontAwesomeIcon icon={faUsers} size='1x' inverse className='tw-mr-7 tw-opacity-90' />
        ) : (
          <FontAwesomeIcon icon={faUsers} size='xl' inverse />
        ),
        iconSource: "font awesome",
        text: "Contacts",
        to: `/${params.site}/contacts`,
        isSelected: () => window.location.pathname.includes("/contacts"),
      },
      {
        show: hasPermits && canReviewPermits && !config.DISABLE_LOCATIONS,
        icon: mobile ? (
          <FontAwesomeIcon icon={faMapLocationDot} size='1x' inverse className='tw-mr-7 tw-opacity-90' />
        ) : (
          <FontAwesomeIcon icon={faMapLocationDot} size='xl' inverse />
        ),
        iconSource: "font awesome",
        text: "Locations",
        to: `/${params.site}/locations`,
        isSelected: () => window.location.pathname.includes("/locations"),
      },
      {
        show: hasCe || hasCrm,
        icon: "chart bar outline",
        iconSource: "semantic",
        text: "Reporting",
        href: `${AppSettings.GOGOV_WEB_URL}/${params.site}/reportlist.php`,
      },
    ],
    bottom: [
      {
        show: true,
        icon: <FontAwesomeIcon icon={faGear} size='xl' inverse />,
        iconSource: "font awesome",
        text: "Admin",
        options: [
          {
            show: true,
            icon: "tasks",
            iconSource: "semantic",
            text: "Admin",
            href: `/${params.site}/admin/landing`,
          },
          {
            show: true,
            tab: "new",
            icon: "external square alternate",
            iconSource: "semantic",
            text: "Legacy Admin",
            href: `${AppSettings.GOGOV_WEB_URL}/${params.site}/admin/index.php`,
          },
          {
            show: true,
            tab: "new",
            icon: "external square alternate",
            iconSource: "semantic",
            text: "System Status",
            href: AppSettings.SYSTEM_STATUS_URL,
          },
          {
            show: true,
            tab: "",
            icon: "external square alternate",
            iconSource: "semantic",
            text: "Release Notes",
            isReleaseNotes: true,
          },
        ],
        isAdmin: true,
      },
      {
        show: true,
        icon: "user outline",
        iconSource: "semantic",
        text: "Account",
        options: [
          {
            show: username === "govout143",
            icon: "lock",
            iconColor: "red",
            iconSource: "semantic",
            text: "Govout143",
            href: `${window.location.origin}/${params.site}/admin/employees/me`,
          },
          {
            show: hasPermits,
            icon: "user outline",
            iconSource: "semantic",
            text: "Permits Profile",
            href: `/${params.site}/profile-new`,
          },
          {
            show: true,
            tab: "new",
            icon: "external square alternate",
            iconSource: "semantic",
            text: "Profile",
            href: `${AppSettings.GOGOV_WEB_URL}/${params.site}/profile.php?cmd=edit`,
          },
          {
            show: true,
            icon: "log out",
            iconSource: "semantic",
            text: "Logout",
            href: `/${params.site}/logout`,
          },
        ],
      },
    ],
  };

  if (mobile) {
    return (
      <>
        <div className='top-aligned'>
          {structure.top
            .filter((item) => item.show)
            .map((item) => (
              <MobileMenuItem key={item.text} item={item} />
            ))}
        </div>
        <div className='top-aligned' style={{ borderTop: "1px solid grey" }}>
          {structure.middle
            .filter((item) => item.show)
            .map((item) => (
              <MobileMenuItem key={item.text} item={item} />
            ))}
        </div>
        <div className='bottom-aligned' style={{ borderTop: "1px solid grey" }}>
          {structure.bottom
            .filter((item) => item.show)
            .map((item) => (
              <MobileMenuItem key={item.text} item={item} />
            ))}
        </div>
      </>
    );
  }

  return (
    <ReleaseNotesContextProvider>
      {structure.top
        .filter((item) => item.show)
        .map((item) => (
          <DesktopMenuItem key={item.text} item={item} expanded={expanded} />
        ))}
      <Divider inverted />
      {structure.middle
        .filter((item) => item.show)
        .map((item) => (
          <DesktopMenuItem key={item.text} item={item} expanded={expanded} />
        ))}
      <StyledBottomNav>
        {structure.bottom
          .filter((item) => item.show)
          .map((item) => (
            <DesktopMenuItem
              key={item.text}
              item={item}
              expanded={expanded}
              firstName={firstName}
              lastName={lastName}
              email={email}
            />
          ))}
      </StyledBottomNav>
    </ReleaseNotesContextProvider>
  );
};

export default (props: any) => {
  const isSmallScreen = useMedia({ query: "(max-width: 640px), (max-height: 450px)" });
  const [expanded, setExpanded] = usePersistedState("menu.expanded", true);
  const child = useMemo(() => props.render(), [props]);
  const marginLeft = expanded ? NavWidth : NavWidthCollapsed;
  const [visible, setVisible] = useState(false);

  return (
    <>
      {isSmallScreen && (
        <div className='EmployeeWrapper--mobile'>
          <SquaredMenu inverted>
            <Menu.Item>
              <Image src={`${AppSettings.ASSETS_URL}/branding/GOGov_logo_only_fullcolor.svg`} />
            </Menu.Item>
            <Menu.Menu position='right'>
              <Dropdown item icon='bars'>
                <Dropdown.Menu>
                  <MenuItems mobile={true} />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </SquaredMenu>
        </div>
      )}
      {!isSmallScreen && (
        <StyledSideNav
          onMouseOver={() => setVisible(true)}
          onMouseOut={() => setVisible(false)}
          expanded={expanded}
          onToggle={() => {
            return;
          }}
          className='hidden-print employee-wrapper'
        >
          <StyledNav>
            <CollapseButton
              circular
              visible={visible.toString()}
              expanded={expanded.toString()}
              onClick={() => setExpanded(!expanded)}
              className={expanded ? "NavWidth" : "NavWidthCollapsed"}
              size='mini'
              icon={expanded ? "chevron left" : "chevron right"}
            />
            <NavHeader expanded={expanded}>
              {!expanded && <Image src={`${AppSettings.ASSETS_URL}/branding/GOGov_logo_only_fullcolor.svg`} />}
              {expanded && (
                <Image src={`${AppSettings.ASSETS_URL}/branding/Full%20Logo%20Color%20%2B%20White%20TextVector.svg`} />
              )}
            </NavHeader>
            <MenuItems expanded={expanded} />
          </StyledNav>
        </StyledSideNav>
      )}
      <Main style={{ marginLeft: isSmallScreen ? 0 : marginLeft }}>{child}</Main>
    </>
  );
};
