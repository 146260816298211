import _ from "lodash";
import selectors from "../selectors";

function defaultHeaders(state: any) {
  return _.pickBy(
    {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("jwt.access_token") as string | null,
      "X-GOGOVAPPS-SITE": selectors.site(state).site,
    },
    _.identity,
  );
}

function defaultHeadersWithSite(site: string) {
  return _.pickBy(
    {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("jwt.access_token") as string | null,
      "X-GOGOVAPPS-SITE": site,
    },
    _.identity,
  );
}

function defaultHeadersWithoutJson(state: any) {
  return _.pickBy(
    {
      "Authorization": localStorage.getItem("jwt.access_token") as string | null,
      "X-GOGOVAPPS-SITE": selectors.site(state).site,
    },
    _.identity,
  );
}

export default {
  defaultHeaders,
  defaultHeadersWithSite,
  defaultHeadersWithoutJson,
};
