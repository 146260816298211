import moment from "moment";
import React from "react";
import jsonData from "../version.json";
import camelcaseKeys from "camelcase-keys";
import _ from "lodash";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//read version number from file
export function readVersion() {
  return jsonData.version;
}

export function SortArray(x: any, y: any) {
  if (x.text.toUpperCase() < y.text.toUpperCase()) {
    return -1;
  }
  if (x.text.toUpperCase() > y.text.toUpperCase()) {
    return 1;
  }
  return 0;
}

export function formatDate(date: string) {
  const options = {
    weekday: "short",
    month: "short",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Date(date).toLocaleDateString("en-us", options as any);
}

export function formatDateNoDayOfWeek(date: string) {
  const options = {
    month: "short",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Date(date).toLocaleDateString("en-us", options as any);
}

export function formatDateNoTime(date: string) {
  const options = {
    month: "short",
    year: "numeric",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("en-us", options as any);
}

// Get distance in days between now and a date
export function daysFromNow(date: string | Date) {
  return Math.abs(moment(date).diff(Date.now(), "days"));
}

// Get a humanized relative date string from now
export function formatDateFromNow(date: string | Date, format = "MMM DD, YYYY [at] h:mm A") {
  // get number of days from date to now
  const days = daysFromNow(date);

  if (days < 7) return moment(date).fromNow();
  if (days >= 7 && days <= 10) return "last week";
  // if larger return the date
  return moment(date).format(format);
}

// Determines if all of an object's values are null or empty
export function isObjectEmpty(object: any) {
  try {
    return Object.values(object).every((x) => x === null || x === "");
  } catch {
    return true;
  }
}

enum USBounds {
  minLat = 14,
  maxLat = 75,
  minLon = -180,
  maxLon = -44,
}

export function isPointWithinUSBounds(lat: number, lon: number) {
  return lat >= USBounds.minLat && lat <= USBounds.maxLat && lon >= USBounds.minLon && lon <= USBounds.maxLon;
}

// Returns the abbreviated name of a US state
export function stateNameToAbbreviation(name: string) {
  const states: any = {
    "arizona": "AZ",
    "alabama": "AL",
    "alaska": "AK",
    "arkansas": "AR",
    "california": "CA",
    "colorado": "CO",
    "connecticut": "CT",
    "district of columbia": "DC",
    "delaware": "DE",
    "florida": "FL",
    "georgia": "GA",
    "hawaii": "HI",
    "idaho": "ID",
    "illinois": "IL",
    "indiana": "IN",
    "iowa": "IA",
    "kansas": "KS",
    "kentucky": "KY",
    "louisiana": "LA",
    "maine": "ME",
    "maryland": "MD",
    "massachusetts": "MA",
    "michigan": "MI",
    "minnesota": "MN",
    "mississippi": "MS",
    "missouri": "MO",
    "montana": "MT",
    "nebraska": "NE",
    "nevada": "NV",
    "new hampshire": "NH",
    "new jersey": "NJ",
    "new mexico": "NM",
    "new york": "NY",
    "north carolina": "NC",
    "north dakota": "ND",
    "ohio": "OH",
    "oklahoma": "OK",
    "oregon": "OR",
    "pennsylvania": "PA",
    "rhode island": "RI",
    "south carolina": "SC",
    "south dakota": "SD",
    "tennessee": "TN",
    "texas": "TX",
    "utah": "UT",
    "vermont": "VT",
    "virginia": "VA",
    "washington": "WA",
    "west virginia": "WV",
    "wisconsin": "WI",
    "wyoming": "WY",
    "american samoa": "AS",
    "guam": "GU",
    "northern mariana islands": "MP",
    "puerto rico": "PR",
    "us virgin islands": "VI",
    "us minor outlying islands": "UM",
  };

  const a: string = name
    .trim()
    .replace(/[^\w ]/g, "")
    .toLowerCase(); //Trim, remove all non-word characters with the exception of spaces, and convert to lowercase
  if (states[a] !== null) {
    return states[a];
  }

  return "";
}

// parses a Ruby hash (in string form) and returns a JavaScript Object
export function parseRubyHash<T = any>(str: string | null | undefined): T {
  if (str == null) return {} as T;
  try {
    str = str.replaceAll("=>", ":");
    return JSON.parse(str) as T;
  } catch (e) {
    return {} as T;
  }
}

// Open links in either the same tab, or a new tab
export function handleLinkClickWithHref(event: React.MouseEvent<HTMLAnchorElement>) {
  if (!event.metaKey && !event.ctrlKey) {
    // if command key (macOS) or control key (Windows and Linux) is not pressed, handle click normally
    return;
  }
  event.preventDefault();
  // if command key (macOS) or control key (Windows and Linux) is pressed, open link in new tab
  window.open(event.currentTarget.href, "_blank");
}

// Open links either the same tab, or a tab. Used for table rows have inner buttons, which you want to click, but not trigger the link
export const handleLinkClick = (url: any, event: React.MouseEvent) => {
  event.preventDefault();
  if (event.metaKey || event.ctrlKey) {
    window.open(url, "_blank");
  } else {
    window.location = url;
  }
};

// Returns a string with the correct byte abbreviation
export function formatBytes(bytes: number, decimals = 0) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Functions as lodash.get, but returns the value as camelCase using a deep object traversal
 * @param data
 * @param path
 */
export function getCamel<T = any>(data: any, path: string, defaultValue?: any): T {
  return camelcaseKeys(_.get(data, path, defaultValue), { deep: true });
}

// Opening a custom context menu
export const openContextMenu = (event: any, setPopupPosition: Function, setShowPopup: Function) => {
  event.preventDefault();
  const { clientX, clientY } = event;
  setPopupPosition({ x: clientX, y: clientY });
  setShowPopup(true);
};

export function makeFullAddressWithObject(
  addressObject?: {
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    attn?: string | null;
  } | null,
): string | null {
  if (!addressObject) return null;
  const { address, address2, city, state, zip, attn } = addressObject;

  let addressAndAddress2 = address;

  if (!!address2) {
    addressAndAddress2 += ` #${address2}`;
  }

  let fullAddress = [addressAndAddress2, city, state].filter((item) => item).join(", ");
  if (zip) {
    fullAddress += " " + zip;
  }
  if (attn) {
    fullAddress = `Attn: ${attn}\n${fullAddress}`;
  }
  return fullAddress;
}

export function makeFullFormattedAddressWithObject(
  addressObject?: {
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    attn?: string | null;
  } | null,
): string | null {
  if (!addressObject) return null;
  const { address, address2, city, state, zip, attn } = addressObject;

  let attnLine = "";
  if (attn) {
    attnLine = `Attn: ${attn}`;
  }

  const addressLine = [address, address2].filter((item) => item).join(" #");
  let cityStateZipLine = [city, state].filter((item) => item).join(", ");

  if (zip) {
    cityStateZipLine += ` ${zip}`;
  }

  const fullFormattedAddress = [attnLine, addressLine, cityStateZipLine]
    .map((line) => line.trim())
    .filter((item) => item)
    .join("\n");
  return fullFormattedAddress;
}

export function makeFullAddress(
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  attn?: string | null,
) {
  let addressAndAddress2 = address;

  if (!!address2) {
    addressAndAddress2 += ` #${address2}`;
  }

  let fullAddress = [addressAndAddress2, city, state].filter((item) => item).join(", ");
  if (zip) {
    fullAddress += " " + zip;
  }
  if (attn) {
    fullAddress = `Attn: ${attn}\n${fullAddress}`;
  }
  return fullAddress;
}

export function makePhysicalAddressWithObject(
  addressObject?: {
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null,
): string | null {
  return makeFullAddressWithObject({ ...addressObject, attn: null });
}

// gets appropriate physical address text
export function getPhysicalAddressText(
  sameMailAndPhysicalAddress: boolean,
  physicalAddress: string,
  physicalAddress2: string,
  physicalCity: string,
  physicalState: string,
  physicalZip: string,
  hasAddress?: boolean,
) {
  if (sameMailAndPhysicalAddress && !hasAddress) {
    return "";
  }
  if (sameMailAndPhysicalAddress) {
    return "Same as mailing address";
  }
  return makeFullAddress(physicalAddress, physicalAddress2, physicalCity, physicalState, physicalZip);
}

export function makeStyledFullAddress(address: string, address2: string, city: string, state: string, zip: string) {
  const topLine = [address, address2].filter((item) => item).join(", ");
  let bottomLine = [city, state].filter((item) => item).join(", ");
  if (zip) {
    bottomLine += " " + zip;
  }

  return (
    <div>
      <p style={{ marginBottom: 0, height: 18 }}>{topLine}</p>
      <p className='subText' style={{ fontSize: 13 }}>
        {bottomLine}
      </p>
    </div>
  );
}

/**
 * Takes a list of objects and returns one object with the selected keys and selected values
 */
export function arrayToObject<Object, Value>(
  array: Object[],
  keySelector: (item: Object) => string,
  valueSelector: (item: Object) => Value,
): { [key: string]: Value } {
  return Object.assign(
    {},
    ...array.map((item) => ({
      [keySelector(item)]: valueSelector(item),
    })),
  );
}

/**
 * Structured way of building api calls
 * @param query Api query
 * @param parseResponse Some function to transform the response, usually a zod parser
 * @returns
 */
export async function buildQuery<Response, ParsedResponse>(
  query: Promise<Response>,
  parseResponse: (response: Response) => ParsedResponse,
): Promise<ParsedResponse> {
  const response = await query;
  return parseResponse(response);
}

// gets the error message for a field
export const fieldError = (errors: any, field: string) => {
  const message = _.get(errors, field);
  if (_.isEmpty(message)) {
    return undefined;
  }
  return {
    content: message,
    pointing: "below",
  };
};
